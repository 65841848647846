.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  background-color: var(--header-color);
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 55px;
  font-family: 'Poppins', sans-serif;
}

.nav {
  display: flex;
  align-items: center;
}

.logo {
  height: 25px;
  width: auto;
  margin-right: 30px;
  cursor: pointer;
}

.menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0px;
  align-items: center;
  cursor: pointer;
}

.menu li {
  margin: 0 10px;
}

.menu li h1 {
  display: block;
  padding: 15px;
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.menu li h1:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.wallet-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  min-width: 140px;
  cursor: default;
}

.wallet-info {
  font-size: 80%;
  margin-right: 20px;
  cursor: default;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* New Wallet Display Styles */
.wallet-dropdown p {
  color: white;
  padding: 8px 0;
  margin: 0;
  font-size: 14px;
}

.wallet-dropdown button {
  width: 100%;
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.wallet-display {
  position: relative;
}

.wallet-button {
  background-color: var(--green-button-color);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.wallet-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #2c2c2c;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1001;
  border-radius: 5px;
  padding: 12px;
  margin-top: 5px;
}

.wallet-dropdown p {
  color: white;
  padding: 8px 0;
  margin: 0;
  font-size: 14px;
  border-bottom: 1px solid #444;
}

.account-button {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  transition: background-color 0.3s;
}

.account-button:hover {
  background-color: #444;
}

.account-button.active {
  background-color: var(--green-button-color);
}

.connect-button {
  background-color: var(--green-button-color);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

/* Adjustments for mobile */
@media (max-width: 768px) {
  .menu {
    display: none;
    position: absolute;
    top: 55px;
    left: 0;
    background-color: var(--card-border-color);
    width: 100%;
    padding: 0px 0;
  }

  .menu.show {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu li {
    text-align: center;
  }

  .menu li a {
    display: block;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid #2c2c2c;
  }

  .menu li:first-child a {
    border-top: 1px solid #2c2c2c;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .line {
    width: 20px;
    height: 3px;
    background-color: white;
    margin: 3px;
  }

  /* Adjust wallet display for mobile */
  .wallet-container {
    margin-right: 10px;
  }

  .wallet-dropdown {
    right: -10px;
    min-width: 140px;
  }
  .wallet-button {
    padding: 8px 12px;
    font-size: 12px;
  }

  .wallet-dropdown {
    right: -10px;
    min-width: 160px;
  }

  .account-button {
    font-size: 12px;
  }
}


.theme-buttons {
  display: flex;
  gap: 0.5rem;
  margin-right: 1rem;
}

.theme-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.theme-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.theme-button.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.rightHeader {
  display: flex;
  margin-right: 10px;
}