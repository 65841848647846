.footer {
  position: fixed;
  background-color: var(--background-color);
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
}

.content-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disclaimer {
  text-align: center;
  max-width: 100%;
  padding: 20px;
}

.disclaimer p {
  background-color: var(--background-color);
  margin: 10px 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--text-color);
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gas-container {
  display: flex;
  align-items: center;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  transition: opacity 0.3s ease;
}

.social-icons a:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .footer {
    display: flex;
  }
  
  .footer-bottom {
    flex-direction: column;
  }

  .disclaimer p {
    font-size: 0.6rem;
  }
}