.scaledContainer {
  transform: scale(0.8);
}

.containerBoard {
  width: 100%;
  max-width: 100%;
  margin-top: 0rem;
}

.titleBoard {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-color);
}

.cardImage {
  max-width: 100%;
  max-height: 15vh;
}


.messageGrid {
  width: 100%;
  max-width: 1500px;
  min-width: 200px;
  max-height: 700px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;  
  row-gap: 1rem;     
  overflow-y: auto;
  padding-right: 1rem;
  margin-bottom: 1rem;
}

.messageGrid > * {
  min-width: 0;
}

.card {
  background-color: var(--card-color);
  border-radius: 8px;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  width: 100%;
  height: 300px;
  position: relative;
}

.cardContent {
  flex-grow: 1;
  padding: 8px 20px;
  overflow: hidden;
  position: relative;
  white-space: pre-wrap;
  word-break: break-word;
  padding-top: 1.5rem;
}

.cardFooter {
  font-size: 0.875rem;
  color: #888;
  padding: 15px;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.imageContainer {
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  margin-top: 0.5rem;
  display: block; /* This ensures the image behaves more like a block element */
}

.messageSummaryGrid {
  display: inline-grid;
  gap: 1rem;
  background-color: var(--card-color);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.gridHeader {
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.leftGroup {
  display: flex;
  gap: 1rem;
  flex: 1; 
  max-width: 70%; 
}

.filterSelect {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--card-color);
  color: var(--text-color);
  border: 1px solid var(--card-border-color);
}

.filterInput {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--card-color);
  color: var(--text-color);
  border: 1px solid var(--card-border-color);
}


/* Style for dropdown options */
.chainSelect option {
  background-color: var(--card-color);
  color: white;
  padding: 5px;
}

/* Style for the selected option */
.chainSelect option:checked {
  background-color: #4CAF50;
  color: white;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--card-color);
  color: var(--text-color);
  border: 1px solid var(--card-border-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: max-content;
  background-color: var(--card-color);
  border: 1px solid var(--card-border-color);
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-option {
  display: block;
  padding: 0.5rem;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--card-border-color);
}

.dropdown-option input {
  display: none;
}

.checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  margin-right: 8px;
  position: relative;
}

.dropdown-option input:checked + .checkmark::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  background-color: #4CAF50;
}

@media (max-width: 1400px) {
  .messageGrid{
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) {
  .messageGrid{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .messageGrid{
    grid-template-columns: repeat(2, 1fr);
  }

  .containerBoard {
    margin-top: 12vh;
  }

  .card {
    height: 240px;
  }

  .filterInput {
    display: none;
  }
}