/* Reset */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

/* Variables for colors */
:root {
  --bg-color: #131313;
  --text-color: #ffffff;
  --font-primary: 'Poppins', sans-serif;
  --font-size-large: 26px;
  --min-width: 220px;
  --max-width: 4200px;
  --min-font-size: 24px;
  --max-font-size: 64px;
}

/* Styling for body */
body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-content {
  width: 100%;
  padding-bottom: 40px;
}

/* Title */
.title {
  width: 100%;
  text-align: center;
  font-size: var(--font-size-large);
  font-family: var(--font-primary);
}

/* Container */
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Input Styling */
input {
  font-size: inherit;
}

/* Media Query for MetaMask in-app browser */
@media screen and (-webkit-min-device-pixel-ratio: 1.5) and (-webkit-max-device-pixel-ratio: 3.5) {
  input {
    font-size: inherit;
  }
}