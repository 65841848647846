.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -60px;
    color: var(--text-color);
    background-color: var(--background-color);
    font-family: Arial, sans-serif;
  }
  
  .home-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 6rem;
    text-align: center;
    text-shadow: 0 0 10px rgba(255,255,255,0.5);
  }
  
  .timer-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0rem;
  }
  
  .timer-segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: var(--timer-seg-color);
    border-radius: 0.5rem;
    width: 15%;
  }

  .timer-value {
    font-size: 6rem;
    font-weight: bold;
    color: var(--text-color);
  }
  
  .timer-label {
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-top: 0.5rem;
    color: var(--text-color);
  }
  
  .home-text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .register-button {
    background-color: var(--green-button-color);
    margin-bottom: 3rem;
    margin-top: 2rem;
    font-size: 1.0rem;
    padding: 1.7rem 2.2rem;
    border-radius: 1.0rem;
    transition: all 0.3s ease;
  }
  
  .button-container {
    display: flex;
    gap: 1rem;
  }
  
  .pulse-button {
    background-color: var(--pulse-button-color);
    color: var(--text-color);
    font-size: 0.8rem;
    padding: 1.5rem 1.5rem;
    transition: all 0.3s ease;
    border-radius: 1.0rem;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: bold;
    color: var(--sec-text-color);
    cursor: pointer;
    border: none;
    outline: none;
  }
  
  button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  @media (max-width: 768px) {
    .home-title {
      margin-bottom: 2rem;
      font-size: clamp(14px, 7vw, 80px);
    }

    .timer-container {
        gap: clamp(2px, 1.1vw, 10px);
    }
  
    .timer-segment {
      width: 5%;
      margin-bottom: 0.5rem;
    }
  
    .timer-value {
      font-size: 1.5rem;
    }
  
    .timer-label {
      font-size: 0.7rem;
    }
  
    .home-text {
        font-size: clamp(14px, 4vw, 60px);
    }
  
    .register-button {
      font-size: 0.9rem;
      padding: 1rem 1.5rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  
    .pulse-button {
      font-size: 0.8rem;
      padding: 1rem 1.2rem;
    }
  }
