/* Dark theme */
.dark {
    --bg-color: #131313;
    --background-color: #131313;
    --text-color: #ffffff;
    --sec-text-color: #ffffff;
    --primary-color: #0056b3;
    --secondary-color: #495057;
    --accent-color: #218838;
    --font-primary: 'Poppins', sans-serif;
    --font-size-large: 26px;
    --pulse-button-color: #4299e1;
    --green-button-color: #51d656; 
    --timer-seg-color: #ffffff1a;
    --header-color: #222222;
    --card-color: #2a2a2a;
    --card-border-color: #3a3a3a;
  }
  
  /* Light theme */
  .light {
    --bg-color: #ffffff;
    --background-color: #ffffff;
    --text-color: #333333;
    --sec-text-color: #ffffff;
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --accent-color: #4CAF50;
    --font-primary: 'Poppins', sans-serif;
    --font-size-large: 26px;
    --pulse-button-color: #d9d9d9;
    --green-button-color: #51d656; 
    --timer-seg-color: #d9d9d9;
    --header-color: #222222;
    --card-color: #d9d9d9;
    --card-border-color: #e9e9e9;
  }
  
  /* Common styles */
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }